import React, { useEffect, useRef, useState } from "react";
import Filter from "./Filter";
import { IoClose } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { selectData } from "../redux/slices/dataSlice";
import Sort from "./Sort";
import {
  setInitFilters,
} from "../redux/slices/filterSlice";
//import FiltersMobile from "./FiltersMobile";

function Filters(props) {
  const [open, setOpen] = useState(-1);
  const [openMobile, setOpenMobile] = useState(false);
  const ref = useRef();
  const { config } = useSelector(selectData);
  const dispatch = useDispatch();
  const data = useSelector(selectData);

  let filters = [];

  if (config.filters) filters = config.filters;

  const onClickFilter = (id) => {
    setOpen(open === id ? -1 : id);
  };

  const onClickToggle = () => {
    document.body.classList.contains("no_scroll")
      ? document.body.classList.remove("no_scroll")
      : document.body.classList.add("no_scroll");
    setOpenMobile(!openMobile);
  };
  const onClickResetFilters = () => {
    dispatch(setInitFilters(data.config.default_filters));
    onClickToggle();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.composedPath().includes(ref.current)) {
        setOpen(-1);
      }
    };

    document.body.addEventListener("click", handleClickOutside);
    window.scrollTo(0, 0);

    return () => document.body.removeEventListener("click", handleClickOutside);
  }, []);

  return (
    <div ref={ref} className="filters">
      <div className="filters_body">
        {filters.map((filter, index) => {
          if(filter.name !== "from_to")
          return (
            <Filter
              key={filter.name}
              id={index}
              filter={filter}
              isOpen={open === index}
              setIsOpen={onClickFilter}
              filterType="desktop"
            />
          );
        })}
        <Sort
          id={filters.length}
          setIsOpen={onClickFilter}
          isOpen={open === filters.length}
        />
      </div>
      <div onClick={() => onClickToggle()} className="filters_toggle">
        Filters
      </div>
      {openMobile ? (
        <div className="filters_mobile">
          <div className="filters_mobile_header">
            <h2>Filters</h2>
            <IoClose onClick={() => onClickToggle()} />
          </div>
          <div className="filters_mobile_body">
            {filters.map((filter, index) => {
              if(filter.name !== "from_to")
              return (
                <Filter
                  key={filter.name}
                  id={index}
                  filter={filter}
                  isOpen={open === index}
                  setIsOpen={onClickFilter}
                  filterType="mobile"
                />
              );
            })}
            <Sort />
          </div>
          <div className="filters_mobile_footer">
            <button className="secondary" onClick={onClickResetFilters}>
              Reset All Filters
            </button>
            <button className="primary" onClick={onClickToggle}>
              Apply
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Filters;
