import React from "react";
import Filters from "./Filters";

function SubNavbar(props) {
  return (
    <div className="sub_navbar">
      <div className="container">
        <Filters />
      </div>
    </div>
  );
}
//        <Favourites />

export default SubNavbar;
