import React, {useEffect, useState} from "react";


function DetailsForm() {
    return (
        <div className="listing-info-form-container">
              <form className="listing-info-form" action="">
                <h2 className="listing-info-form_title">Request Information</h2>
                <h3 className="listing-info-form_subtitle">
                  Complete the form and we'll be in touch
                </h3>
                <input
                  className="listing-info-form_input"
                  type="text"
                  placeholder="Name"
                />
                <input
                  className="listing-info-form_input"
                  type="email"
                  placeholder="Email"
                />
                <input
                  className="listing-info-form_input"
                  type="tel"
                  placeholder="Phone number"
                />
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  className="listing-info-form_textarea"
                  placeholder="What would you like to know about this property?"
                />
                <input
                  className="listing-info-form_button"
                  type="button"
                  value="Submit request"
                />
              </form>
        </div>
    )
}

    export default DetailsForm