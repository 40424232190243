import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addListingToFav,
  removeListingFromFav,
  selectFavourite,
} from "../../redux/slices/favouriteSlice";
import { Link } from "react-router-dom";

function Index({
  mls_id,
  title,
  price,
  imgs,
  info,
  city,
  bedrooms,
  baths,
  garage,
  acreage,
  address,
  is_new,
  is_vt,
  is_reduced,
  is_foreclosure,
  uri,
  isFavourite,
  hasPool,
  baseURL,
}) {
  const dispatch = useDispatch();

  const listing = { bedrooms, baths, mls_id };

  const onClickFav = () => {
    dispatch(addListingToFav(listing));
  };

  const onClickUnFav = () => {
    dispatch(removeListingFromFav(listing));
  };

  const img = imgs[0];

  return (
    <div className="listing">
      <div className="card">
        <div className="cover">
          <Link to={baseURL + "/view/" + mls_id}>
            {is_vt === 1 ? (
                <img src={baseURL+'/img/360.png'}  className="tour360" />
            ) : is_foreclosure ? (
              <img className="ribbon" src={baseURL+'/img/yellow_ribbon.png'} />
            ) : is_reduced ? (
              <img className="ribbon" src={baseURL+'/img/red_ribbon.png'} />
            ) : (
              is_new && <img className="ribbon" src={baseURL+'/img/green_ribbon.png'} />
            )}
            <div className="price">${price}</div>
            {!isFavourite ? (
              <img
                className="favourite_icon"
                src={baseURL+'/img/heart_icon_outline.svg'}
                alt=""
                onClick={onClickFav}
              />
            ) : (
              <img
                className="favourite_icon_active"
                src={baseURL+'/img/heart_icon_filled.svg'}
                alt=""
                onClick={onClickUnFav}
              />
            )}
            <img src={img} alt="" className="cover_image" />
          </Link>
        </div>
        <div className="card_body">
          
          <Link to={uri}>
            <div className="city_address">
            <h4>{city}</h4>
            <p className="a_right">{address}</p>
            </div>
          </Link>
          
          <div className="props">
            {hasPool == 1 ? (
              <div className="prop">
                <img src={baseURL+'/img/pool.png'} alt="" className="" />
              </div>
            ) : (
              ""
            )}
            {bedrooms !== "0" && bedrooms !== null ? (
              <div className="prop">
                <img src={baseURL+'/img/bed.png'} alt="" className="" />
                {bedrooms}
              </div>
            ) : (
              ""
            )}
            {baths !== 0 && baths !== null ? (
              <div className="prop">
                <img src={baseURL+'/img/bath.png'} alt="" className="" />
                {baths}
              </div>
            ) : (
              ""
            )}
            {garage ? (
              <div className="prop">
                <img src={baseURL+'/img/garage.png'} alt="" className="" />
                {garage}
              </div>
            ) : (
              ""
            )}
            {acreage !== 0.0 ? (
              <div className="prop">
                <img src={baseURL+'/img/acreage.png'} alt="" className="" />
                {acreage}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
