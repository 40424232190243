import { createSlice } from "@reduxjs/toolkit";
import qs from "qs";

const initialState = {
  searchValue: "",
  currentPage: 1,
  filters: [],
  filterParams: {},
  sort: "priceLtH",
  queryString: "",
  view: "List",
  mapBounds: "",
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setCurrentPage(state, action) {
      //console.log('setCurrentPage', action.payload);
      state.currentPage = action.payload;
    },
    setQS2Filters(state, action) {
      //console.log('setQSFilters', action.payload);
      const params = action.payload.params;
      let filters = [];
      for( var param in params){
        //console.log(param,params[param]);
        if(param==='q')
          state.searchValue = params[param];
        if(param==='page')
          state.currentPage = params[param];
        else if(param==='sortBy')
          state.sort = params[param];
        else{
          params[param].split(',').forEach( 
            (e) => filters.push({name: param, value: e})
          );
        }
      }
      state.filters = filters
    },
    setInitFilters(state, action) {
      //console.log('setInitFilters', action.payload);
      if(action.payload.length)
        state.filters = action.payload;
    },
    addFilter(state, action) {
      //console.log('addFilter', action.payload);
      state.filters.push({ ...action.payload });
      state.currentPage = 1;
    },
    setFilter(state, action) {
      //console.log('setFilter', action.payload);
      const {name, value} = action.payload;
      state.filters = state.filters.filter(
        (obj) => !(obj.name===name)
      );
      state.filters.push({ ...action.payload });
    },
    removeFilter(state, action) {
      //console.log('removeFilter', action.payload);
      const {name, value} = action.payload;
      //console.log(name, value)
      state.filters = state.filters.filter(
        (obj) => !(obj.name===name && (obj.value === value || value==='*'))
      );
    },
    setFilterParams(state, action) {
      //console.log('setFilterParams', action.payload);
      state.filterParams = action.payload;
    },
    setSort(state, action) {
      //console.log('setSort', action.payload);
      state.sort = action.payload;
    },
    setViewType(state, action) {
      console.log('setViewType', action.payload);
      state.view = action.payload;
    },
    setMapBounds(state, action) {
      //console.log('setMapBounds', action.payload);
      state.mapBounds = action.payload;
    },
    resetFilters: () => initialState,
  },
});

export const selectFilter = (state) => state.filter;

export const {
  setCurrentPage,
  setSearchValue,
  setInitFilters,
  setQS2Filters,
  addFilter,
  removeFilter,
  setFilter,
  setSort,
  setViewType,
  resetFilters,
  setMapBounds,
  setFilterParams,
} = filterSlice.actions;

export default filterSlice.reducer;

export const buildQS = (state) => {
  const {currentPage, searchValue, sort, filterParams } = state;
  const params = { page: currentPage };
  const queryString = qs.stringify({...filterParams, ...params, sortBy: sort}, { skipNulls: true });
  return queryString;
}
export const buildFilterParams = (state) =>{
  const {filters, view, mapBounds} = state;
  let filterParams = {};
  var hash = filters.reduce(function (map, obj) {
    if (typeof map[obj.name] !== "undefined") map[obj.name].push(obj.value);
    else map[obj.name] = [obj.value];
    return map;
  }, []);
  for (var key in hash) filterParams[key] = hash[key].join(",");
  
  if(view === 'Map' && mapBounds !== "")
    filterParams['bounds'] = mapBounds;
  //console.log('ActiveFilters buildFilterParams',filterParams, filters, view, mapBounds);
  return filterParams;
}
