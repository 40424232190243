import React, { useEffect, useRef, ReactElement, useState } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import {
  MarkerClusterer,
  SuperClusterAlgorithm,
} from "@googlemaps/markerclusterer";

import { selectData } from "../redux/slices/dataSlice";
import { setMapBounds, setSort, } from "../redux/slices/filterSlice";
import { useDispatch, useSelector } from "react-redux";
//import Listing from "../components/Listing";
import { renderToString } from "react-dom/server";
import Listing from "../components/Listing/Map";

function MlsMap({
  center,
  zoom,
  listings,
  baseURL,
}: {
  center: google.maps.LatLngLiteral,
  zoom: number,
}) {
  
  const [map, setMap] = useState(0);
  const [markerLib, setMarkerLib] = useState(null);
  const [infoWindow, setInfoWindow] = useState(null);
  const [markers, setMarkers] = useState([]);
  const ref = useRef();
  const data = useSelector(selectData);
  const dispatch = useDispatch();

  const onBoundsChanged = (googleMap) => {
    const bounds = googleMap.getBounds().toUrlValue();
    //console.log("handleBoundsChanged", bounds);
    dispatch(setMapBounds(bounds));
  };
  
  useEffect(() => {
    if (ref.current) {
      async function initMap() {
        dispatch(setMapBounds(""));
        const { Map, InfoWindow } = await google.maps.importLibrary("maps");
        const marker = await google.maps.importLibrary("marker");
        setMarkerLib(marker);
        const infoWindow = new InfoWindow();
        setInfoWindow(infoWindow);
        const googleMap = await new Map(ref.current, {
          center,
          zoom,
          mapId: "AQUA_MAP",
        });
        googleMap.addListener("bounds_changed", () =>
            onBoundsChanged(googleMap)
        );
        googleMap.addListener("click", () =>
            infoWindow.close()
        );
        setMap(googleMap);        
        //console.log("googleMap created", map, googleMap);
      }
      initMap();
    }
  }, [ref]);

  useEffect(() => {
    if (data.listings.length > 0 && markerLib !== null)
      addClusterMarkers({ listings: data.listings, map });
  }, [data.listings]);

  const addSingleMarkers = ({ listings, map }) => {
    let markersList = [];
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(null);
    }
    listings.map((listing) => {
      if (listing.lat) {
        const content = document.createElement("div");
        content.classList.add("propertyLabel");
        content.innerHTML = "<span>$" + priceK(listing.price) + "</span>";

        const marker = new markerLib.AdvancedMarkerElement({
          position: { lat: listing.lat, lng: listing.lng },
          content: content,
          map,
        });
        marker.addListener("click", () => {
          infoWindow.close();
          const content =
            `<a href="./view/`+listing.mls_id+`" target="_blank">` +
            renderToString(<Listing baseURL={baseURL} {...listing} />)
             + `</a>`;
          //console.log(content);
          infoWindow.setContent(content);
          infoWindow.open(marker.map, marker);
        });
        markersList.push(marker);
      }
    });

    setMarkers(markersList);
  };
  const addClusterMarkers = ({ listings, map }) => {
    addSingleMarkers({ listings, map });
    //console.log("addSingleMarkers", map, listings.length);

    new MarkerClusterer({
      markers,
      map,
      algorithm: new SuperClusterAlgorithm({
        radius: 1,
      }),
    });
  };

  return <div ref={ref} id="map" className="Map" />;
}

const render = (status: Status): ReactElement => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
};

function MapView({baseURL}) {
  const data = useSelector(selectData);
  if (data.view !== "Map") return "";
  const apiKey = data.config.gmapToken;
  const center = data.config.mapCenter;
  const zoom = 4;

  //console.log("MapView render");
  if (!apiKey) {
    return <div>Cannot display the map: google maps api key missing</div>;
  }

  return (
    <Wrapper apiKey={apiKey} render={render}>
      <MlsMap center={center} zoom={12} listings={data.listings} baseURL={baseURL} />
    </Wrapper>
  );
}

const priceK = (price) => {
  const priceInt = parseInt(price.replaceAll(",", "")) / 1000;
  if (priceInt < 1000) return Math.round(priceInt) + "K";
  else return Math.round(priceInt) / 1000 + "M";
};

export default MapView;
