import React from "react";
import { Link } from "react-router-dom";


function Listing({
  mls_id,
  title,
  price,
  imgs,
  info,
  city,
  bedrooms,
  baths,
  garage,
  acreage,
  address,
  is_new,
  is_vt,
  is_reduced,
  is_foreclosure,
  uri,
  isFavourite,
  baseURL,
}) 
{
  const img = imgs[0];
  const img_urls = imgs.map((i) => ({'url':i}))
  //console.log(img_urls)

  return (
    <div className="listing">
      <div className="card">
        <div className="cover">
          {is_foreclosure ? (
            <img className="ribbon" src={baseURL+'/img/yellow_ribbon.png'} />
          ) : 
            is_reduced ? (
              <img className="ribbon" src={baseURL+'/img/red_ribbon.png'} />
            ) : (
              is_new && (
                <img className="ribbon" src={baseURL+'/img/green_ribbon.png'} />
              )
            )
          }
          <div className="price">${price}</div>
          <img className="cover_image" src={img} alt="" style={{'width':'300px'}} />
        </div>
        <div className="card_body">
          {0 && is_vt === 1 ? (
            <div className="tour360">
              <img src={baseURL+'/img/360.png'}/>
            </div>
          ):''}
            <h4>{city}</h4>
          <p>{address}</p>
          <div className="props">
            {bedrooms !== "0" && bedrooms !== null ? (
              <div className="prop">
                <img src={baseURL+'/img/bed.png'} alt="" className="" />
                {bedrooms}
              </div>
            ) : (
              ""
            )}
            {baths !== 0 && baths !== null ? (
              <div className="prop">
                <img src={baseURL+'/img/bath.png'} alt="" className="" />
                {baths}
              </div>
            ) : (
              ""
            )}
            {garage ? (
              <div className="prop">
                <img src={baseURL+'/img/garage.png'} alt="" className="" />
                {garage}
              </div>
            ) : (
              ""
            )}
            {acreage !== 0.0 ? (
              <div className="prop">
                <img src={baseURL+'/img/acreage.png'} alt="" className="" />
                {acreage}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Listing;
