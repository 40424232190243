import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchData = createAsyncThunk(
  "data/fetchData",
  async ({ apiURL, sort, filters }) => {
    let postData = {
      init: 1,
      type: "grid",
    };
    postData = {...filters, ...postData}
    const { data } = await axios.post(
      apiURL+'listings',
      postData
    );
    //console.log('data/fetchData',postData,data)
    return data;
  }
);

export const fetchConfig = createAsyncThunk("data/fetchConfig", async () => {
  const { data } = await axios.get(
    "https://adriennegremorehomes.com/listings3/api/config"
  );
  console.log('fetchConfig',data)
  return data;
});

const initialState = {
  listings: [],
  config: [],
  pageCount: 0,
  status: "loading",
  view: "List",
  listData: null,
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setListData(state, action) {
      state.listData = action.payload;
    },
    setDataViewType(state, action) {
      //state.listings = [];
      //state.pageCount = 0;
      state.view = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => {
      state.listings = [];
      state.pageCount = 0;
      state.status = "loading";
    });
    builder.addCase(fetchData.fulfilled, (state, action) => {
      state.listings = action.payload.listings;
      //state.companyName = action.payload.companyName;
      state.pageCount = Math.ceil(action.payload.listings.length / 60);
      state.status = "success";
    });
    builder.addCase(fetchData.rejected, (state) => {
      state.listings = [];
      state.pageCount = 0;
      state.status = "error";
    });

    builder.addCase(fetchConfig.fulfilled, (state, action) => {
      state.config = action.payload.config;
    });
  },
});

export const selectData = (state) => state.data;

export const { setListData, setDataViewType } = dataSlice.actions;

export default dataSlice.reducer;
