import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  favListings: [],
  count: 0,
};

const favouriteSlice = createSlice({
  name: "favourite",
  initialState,
  reducers: {
    addListingToFav(state, action) {
      if (
        !(
          state.favListings.filter(
            (obj) => obj.mls_id === action.payload.mls_id
          ).length > 0
        )
      ) {
        state.favListings.push(action.payload);
        state.count++;
        window.localStorage.setItem("favListings", JSON.stringify(state));
      }
    },
    removeListingFromFav(state, action) {
      if (
        state.favListings.filter((obj) => obj.mls_id === action.payload.mls_id)
          .length > 0
      ) {
        state.favListings = state.favListings.filter(
          (obj) => obj.mls_id !== action.payload.mls_id
        );
        state.count--;
        window.localStorage.setItem("favListings", JSON.stringify(state));
      }
    },
    setFavListings(state, action) {
      state.favListings = action.payload.favListings;
      state.count = action.payload.count;
    },
  },
});

export const selectFavourite = (state) => state.favourite;

export const { addListingToFav, removeListingFromFav, setFavListings } =
  favouriteSlice.actions;

export default favouriteSlice.reducer;
