import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectData, fetchConfig, setListData } from "../../redux/slices/dataSlice";
import { FaCamera } from "react-icons/fa";
import DetailsForm from "./DetailsForm";
import ModalPopup from "./ModalPopup";
import ContentLoader from "react-content-loader"



function Details({mls_id}){
    const data = useSelector(selectData);
    const dispatch = useDispatch();
    const [listing, setListing] = useState([]);
    const [showModal, setShowModal] = useState(null);
    const [imageLoaded, setImageLoaded] = useState(null);

    //console.log('Details', mls_id)
    const fetchListing = async ({apiURL, mls_id}) => {
        let listData = await (await fetch(apiURL+'view/'+mls_id)).json()
        dispatch(setListData(listData));
        setListing( listData );
    }
    useEffect(() => {
        if(data.config.length==0)
          dispatch(fetchConfig());
    },[]);
    useEffect(() => {
        if( typeof data.config.apiURL !== 'undefined'){
            fetchListing({mls_id, apiURL: data.config.apiURL});
        }
    }, [data.config.apiURL, mls_id]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const showMap = () => {
        setShowModal('Map');
    }
    const showImages = () => {
        //alert('Show Images');
        setShowModal('Gallery');
    }
    const closeModal = () =>{
        setShowModal(null);
    }
    const mainImageLoaded = () => {
        //alert('loaded')
        setImageLoaded(1);
    }

    if(typeof listing.error !== 'undefined')
        return (
            <div className="not-found-container">
                <h1 className="not-found-title">404</h1>
                <p className="not-found-text">{listing.error}</p>
                <a href="/" className="not-found-home-link">Home</a>
            </div>
        )
    if(typeof listing.mls_id === 'undefined')
        return (
            <div> Loading ... </div>
        )
    return (
      <main>
        <div className="container">
          
          <div className="listing-info_wrapper">
            <div className="back-button" onClick={() => window.history.back()}>Back to Search Results</div>
            { showModal !== null ? (
                <ModalPopup 
                    closeModal={closeModal}
                    showModalType={showModal}
                    listing={listing}
                    config={data.config}
                />
            ):''
            }
            
            <div className="listing-info_grid">
                <div className="listing-info_container">
                    <div className="img-container" style={{"position": "relative"}}>
                        <div className="listing-info_image_cnt" onClick={showImages}>
                            <FaCamera size={20}/> <span>{listing.imgs.length}</span>
                        </div>
                        { !imageLoaded && (<ImageLoader />) }
                        <img
                            src={listing.imgs[0]}
                            onClick={showImages}
                            className={"listing-info_image"}
                            onLoad={mainImageLoaded}
                            style={ !imageLoaded ? {'display':'none'} : {}}
                        />
                    </div>
                    <h1 className="listing-info-title">
                        <span>
                            {listing.address}, {listing.city}<span className={"state_zip"}>, {listing.state} {listing.zip}</span>
                        </span>
                        <span>
                            ${listing.price} | MLS {listing.mls_id}
                        </span>
                    </h1>
                    <div className="listing-info_top">
                        {listing.options_top.map((item,index)=>(
                            <div key={'top_'+index}><h3>{item[1]}</h3><span>{item[0]}</span></div>
                        ))}
                    </div>
                    <div className="listing-info_description">
                        {listing.remarks}
                    </div>
                    <div className="listing-info_list">
                    {listing.options.map((items, index) => (
                        <div key={'h_'+index} className="row_container">
                            <div className="header" >{items[0]}</div>
                            {items[1].map((item,iindex) => (
                                <div className="row" key={index+'_'+iindex}>
                                    <b>{item[0]}:</b> {item[1]}
                                </div>
                            ))}
                        </div>
                    ))}
                    </div>
                    <div onClick={showMap} style={{'position': 'relative'}}>
                        <iframe 
                            src={"https://www.google.com/maps?q="+ listing.lat +","+ listing.lng +"&t=p&z=17&output=embed"}
                            width="100%"
                            height="450"
                            frameBorder="0"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            aria-hidden="false"
                            tabIndex="0"
                            onClick={showMap}
                        />
                        <div className="listing-info_over_map"></div>
                    </div>
                </div>
                <DetailsForm />
            </div>
{/*}
            <div className="listing-info-gallery">
                {listing.imgs.map( (img, index) => { 
                    return (
                        <a href="#" key={index} className="listing-info-gallery_link">
                            <img
                            src={img}
                            className="listing-info-gallery_image"
                            />
                        </a>
                    )
                } )}
            </div>
            */}
          </div>
        </div>
      </main>
  );
}

export default Details;

const ImageLoader = (props) => (
    <ContentLoader
      viewBox="0 0 500 500"
      height={410}
      width={750}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <path d="M484.52,64.61H15.65C7.1,64.61.17,71.2.17,79.31V299.82c0,8.12,6.93,14.7,15.48,14.7H484.52c8.55,0,15.48-6.58,15.48-14.7V79.31C500,71.2,493.07,64.61,484.52,64.61Zm-9,204.34c0,11.84-7.14,21.44-15.94,21.44H436.39L359.16,171.52c-7.1-10.92-19.67-11.16-27-.51L258.64,277.94C253.78,285,245.73,286,240,280.2l-79.75-80.62c-6-6.06-14.33-5.7-20,.88L62.34,290.39H40.63c-8.8,0-15.94-9.6-15.94-21.44V110.19c0-11.84,7.14-21.44,15.94-21.44H459.54c8.8,0,15.94,9.6,15.94,21.44Z" />
      <ellipse cx="120" cy="140" rx="28" ry="28" />
    </ContentLoader>
  )