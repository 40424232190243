import Helmet from "react-helmet";
import { useSelector } from "react-redux";
import { selectData } from "../redux/slices/dataSlice";
import { selectFilter } from "../redux/slices/filterSlice";

const HeadTags = ({mls_id, baseURL = ''}) => {
    let title = "AquaMLS system";
    let description = "AquaMLS - Reasl Estate Search System";
    const urlPath = window.location.protocol + '//' + window.location.host + baseURL;
    let image = urlPath + '/logo512.png';
    const filter = useSelector(selectFilter);
    const data = useSelector(selectData);

    if(mls_id){
      if( data.listData ){
        title = data.listData.title + ' $' + data.listData.price + ' - ' + data.config.companyName;
        description = data.listData.title + ' @ ' + data.listData.address + " - " + data.listData.options_top.map( (opt) => {return " " + opt[0] + ": " + opt[1]} );
        image = data.listData.imgs[0]
      }
    }

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="description" key="description" content={description} />
        <meta name="title" key="title" content={title} />
        <meta property="og:title" key="og:title" content={title} />
        <meta property="og:locale" key="og:locale" content="en_US" />
        <meta charSet="utf-8" />
        <meta property="og:type" key="og:type" content="website" />
        <meta
          property="og:description"
          key="og:description"
          content={description}
        />
        <meta
          property="og:image"
          key="og:image"
          content={image}
        />  
      </Helmet>
    );
};

export default HeadTags;
