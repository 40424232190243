import { useDispatch, useSelector } from "react-redux";
import { selectFilter, removeFilter, setInitFilters, setViewType, setQS2Filters, setFilterParams, setMapBounds, buildQS, buildFilterParams } from "../redux/slices/filterSlice";
import { fetchData, selectData, fetchConfig, setDataViewType } from "../redux/slices/dataSlice";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import { FaMapMarkedAlt, FaList } from "react-icons/fa";


import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useCallback } from "react";
import debounce from 'lodash.debounce';
import {
    selectFavourite,
    setFavListings,
} from "../redux/slices/favouriteSlice";
import qs from "qs";



function ActiveFilters( {baseURL} ) {
    const dispatch = useDispatch();
    const filter = useSelector(selectFilter);
    const data = useSelector(selectData);
    const config = data.config;
    const count = data.listings.length;
    const navigate = useNavigate();
    const isMounted = useRef(false);
    const favourite = useSelector(selectFavourite);
    //console.log('ActiveFilters', data, filter);
    

    const onDeleteFilter = ({name, value}) => {
        dispatch(removeFilter({ name, value }));
    };
    const onClickResetFilters = () => {
        dispatch(setInitFilters(config.default_filters));
    };
    const onMapViewSwitch = () => {
        if(filter.view === 'Map'){
            dispatch(setViewType('List'));
            dispatch(setDataViewType('List'));
            dispatch(setMapBounds(''));
        }
        else{
            dispatch(setViewType('Map'));
            dispatch(setDataViewType('Map'));
        }
    };
    
  
    const getData = (fs, apiURL) => {
  /*
      if (window.localStorage.getItem("favListings")) {
        dispatch(
          setFavListings(JSON.parse(window.localStorage.getItem("favListings")))
        );
      }
  */
      dispatch(fetchData({ apiURL, filters: {...fs.filterParams, sortBy:fs.sort} }));
      window.scrollTo(0, 0);
    };
  
    const debouncedGetData = useCallback(
      debounce( (fs,apiURL) => {
        getData(fs,apiURL)
      }, 500 )
    , []);
  
    useEffect(() => {
      //console.log('fetchConfig if needed: config.apiURL',data.config.apiURL)
      if( typeof data.config.apiURL === 'undefined'){
        dispatch(fetchConfig());
      }
    }, [data.config.apiURL]);

    useEffect(() => {
      //console.log('data.config.default_filters',data.config.default_filters,isMounted.current)
      if ( typeof data.config.apiURL !== 'undefined' ) {
        if(filter.filters.length)
          dispatch(setInitFilters([]));
        else
          dispatch(setInitFilters(data.config.default_filters));
      }
    }, [data.config.apiURL]);

    useEffect(() => {
      //console.log('debouncedGetData', filter.filterParams, isMounted)
      if (isMounted.current)
        debouncedGetData(filter,data.config.apiURL);
    }, [filter.filterParams]);

    useEffect(() => {
      //console.log('Sort By changed - getData')
      if (isMounted.current)
        getData(filter,data.config.apiURL);
    }, [filter.sort]);

    useEffect(() => {
      //console.log('data.config changed', data.config);
      if( typeof data.config.apiURL !== 'undefined'){
        //console.log('isMounted',isMounted)
        isMounted.current = true;
        if (window.location.search) {
          //console.log('window.location.search', window.location.search)
          const params = qs.parse(window.location.search.substring(1));
          dispatch(setQS2Filters({params}));
        }
        window.addEventListener("popstate", (e) => {
          if (window.location.search) {
            const params = qs.parse(window.location.search.substring(1));
            //console.log('popstate',params)
            dispatch(setQS2Filters({params}));
          }
        });
      }
    }, [data.config]);

    useEffect(() => {
      const queryString = buildQS(filter);
      //console.log('buildQS',filter,queryString)
      if (isMounted.current)
        navigate(`${baseURL}/?${queryString}`);
    }, [filter.currentPage, filter.searchValue, filter.sort, filter.filterParams]);

    useEffect(() => {
      const filterParams = buildFilterParams(filter);
      //console.log('filter changed', filter.searchValue, filter.filters, filterParams, '<>', filter.filterParams, isMounted)
      //console.log('JSON.stringify(filterParams) !== JSON.stringify(filter.filterParams)',filterParams,filter.filterParams);
      if(isMounted.current && JSON.stringify(filterParams) !== JSON.stringify(filter.filterParams)){
        //console.log('UPDATE filter.filterParams', filterParams)
        dispatch(setFilterParams(filterParams));
      }
    }, [filter.searchValue, filter.filters, filter.mapBounds]);

    
    if (filter.filters.length && typeof(config.filters) !== 'undefined' || count>0)
        return (
        <div className="active_filters">
          <div className="list">
            {filter.filters.map( ({name, value}) => {
                const params = {name, value};
                let ftValue = '';
                if( name.indexOf('_from')>0 || name.indexOf('_to')>0){
                    ftValue = value;
                    value = name;
                    name = 'from_to';
                }
                //console.log('ActiveFilters', name, config.filters, value)
                let filter = config.filters.find(x => x.name===name)
                let title = ''
                if(name === 'q'){
                  title = 'Search: ' + value;
                  filter = {title: name}
                }
                else if(name === 'subdivision'){
                  title = value;
                  filter = {title: name}
                }
                else{
                  if(typeof filter === 'undefined')
                    return '';
                  const option = filter.options.find(x => x.value===value)
                  title = option.title;
                }
                if(name === 'from_to')
                    title += ftValue;
                return (
                    <div key={value} className="active_filters_item" title={filter.title}>
                        {title} <span className="active_filters_item_close" onClick={(e)=>onDeleteFilter(params)}><IoClose /></span>
                    </div>
                )
            } ) }
            {data.status === "success" && (
                count>0 ? (
                  <>
                    <span className="active_filters_count">{count===1000?'1000+':count} listings</span>
                  </>
                ):(
                    <>
                    <span className="active_filters_count">There are no listings that match this search criteria.</span>
                    <span className="active_filters_count"><Link onClick={onClickResetFilters}><u>Click here</u></Link>&nbsp;to reset your search</span>
                    </>
                )
            )}
          </div>
          { count> 0 && (<div className="map-switch"><Link onClick={onMapViewSwitch}>{filter.view === 'Map'? (<> <FaList /> <span>LIST VIEW</span> </>) : ( <> <FaMapMarkedAlt /> <span>MAP VIEW</span> </> ) }</Link></div>)}
        </div>
        );
    else return '';
  }
  
  export default ActiveFilters;
