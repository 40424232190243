import React from "react";

function Footer({baseURL}) {
  return (
    <footer>
      <div className="container">
        <a href="http://webmizer.com/" target="_blank">
          <span><img src={ baseURL + "/img/webmizer.png" } alt="WebMizer" /></span> <span>&copy; {(new Date()).getFullYear()} Powered by WebMIZER.com</span> </a>
      </div>
    </footer>
  );
}

export default Footer;
