import React from "react";
import ContentLoader from "react-content-loader";

function Skeleton(props) {
  return (
    <ContentLoader
      speed={2}
      width={302}
      height={274}
      viewBox="0 0 302 274"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="0" rx="0" ry="0" width="300" height="190" />
      <rect x="154" y="249" rx="0" ry="0" width="144" height="22" />
      <rect x="0" y="200" rx="0" ry="0" width="179" height="17" />
      <rect x="0" y="224" rx="0" ry="0" width="200" height="17" />
    </ContentLoader>
  );
}

export default Skeleton;
