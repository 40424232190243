import React, { useState } from "react";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
//import { Link } from "react-router-dom";

import {
  selectFilter,
  addFilter,
  removeFilter,
  setFilter,
} from "../redux/slices/filterSlice";

function Filter({ id, filter, isOpen, setIsOpen, filterType }) {
  const dispatch = useDispatch();
  const filtersActive = useSelector(selectFilter);
  const [openFilter, setOpenFilter] = useState(-1);

  const onChangeFilter = (e) => {
    const { name, value, checked } = e.target;
    if (checked) dispatch(addFilter({ name, value }));
    else dispatch(removeFilter({ name, value }));
  };
  const onChangeInputFilter = (e) => {
    const { id, value } = e.target;
    dispatch(setFilter({name:id, value: value}))
  }
  const getFilterValue = (name) =>{
    return filtersActive.filters.find(x => x.name===name)?''+filtersActive.filters.find(x => x.name===name).value:'';
  }
  const bbBtnClassName = (filterName, value) =>{
    const currentVal = filtersActive.filters.find(x => x.name===filterName);
    return filtersActive.filters.some((x) => x.name===filterName && x.value===value) || (value===0 && !currentVal)?"filter_bb_option filter_bb_active":"filter_bb_option";
  }
  const onClickFilter = (id) => {
    if (openFilter === id) {
      setOpenFilter(-1);
    } else {
      setOpenFilter(id);
    }
  };

  if(filterType==='desktop')
    return (
      <div className="filter">
        <div
          onClick={() => setIsOpen(id)}
          className={isOpen ? "filter_header open" : "filter_header"}
        >
          {filter.title}
          {!isOpen ? <AiFillCaretDown /> : <AiFillCaretUp />}
        </div>
        {isOpen ? (
          <div className="filter_body">
            {filter.type === "checkbox" && (
              filter.options.map((option) => {
                return (
                  <div key={option.value} className="filter_option">
                    <input
                      onChange={onChangeFilter}
                      name={filter.name}
                      id={option.value}
                      value={option.value}
                      type="checkbox"
                      checked={filtersActive.filters.some(
                        (f) => f.name === filter.name && f.value === option.value
                      )}
                    />
                    <label htmlFor={option.value}>{option.title}</label>
                  </div>
                );
              })
            )}
            {filter.type === "price" && (
              <div className="filter_price">
                <div>
                  $ <input id="price_from" onChange={onChangeInputFilter} value={getFilterValue('price_from')} inputMode='decimal' onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} />&nbsp;to&nbsp;
                  $ <input id="price_to" onChange={onChangeInputFilter} value={getFilterValue('price_to')} inputMode='decimal' onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} />
                </div>
                <div id="filter_price_column" style={{ float: "left" }}>
                  {filter.options.from.map((option) => {
                    const [value,caption] = [option, option === 0?'Any':'$'+option.toLocaleString()];
                    return (
                      <div className="filter_option" key={value}><button id="price_from" value={value} onClick={onChangeInputFilter}>{caption}</button></div>
                    )
                  })}
                </div>
                <div id="filter_price_column" style={{ float: "right" }}>
                  {filter.options.to.map((option) => {
                    const [value,caption] = [option, option === 99999999?'Any':'$'+option.toLocaleString()];
                    return (
                      <div className="filter_option" key={value}><button id="price_to" value={value} onClick={onChangeInputFilter}>{caption}</button></div>
                    )
                  })}
                </div>
              </div>
            )}
            {filter.type === "bb" && (
              <div>
                <span>Number of Bedrooms</span>
                <div>
                  {filter.options.bedrooms_from.map((option) => {
                    const [value,caption] = [option, option === 0?'Any':option+'+'];
                    return (
                      <button key={value} className={bbBtnClassName('bedrooms_from', value)} onClick={()=>{dispatch(setFilter({name:'bedrooms_from', value}))}}>{caption}</button>
                    )
                  })}
                </div>
                <span>Number of Bathrooms</span>
                <div>
                  {filter.options.baths_from.map((option) => {
                    const [value,caption] = [option, option === 0?'Any':option+'+'];
                    return (
                      <button key={value} className={bbBtnClassName('baths_from', value)} onClick={()=>{dispatch(setFilter({name:'baths_from', value}))}}>{caption}</button>
                    )
                  })}
                </div>
              </div>
            )}
            {filter.type === "more" && (
              <div>
                <div className="filter_more_minmax">
                  <span>Square Feet</span> 
                  <input id="sqft_from" key="sqft_from" onChange={onChangeInputFilter} value={getFilterValue('sqft_from')} placeholder="Min" inputMode='decimal' onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} /> 
                  <span className="filter_more_to_label">to</span> 
                  <input id="sqft_to" key="sqft_to" onChange={onChangeInputFilter} value={getFilterValue('sqft_to')} placeholder="Max" inputMode='decimal'  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} />
                </div>
                <div className="filter_more_minmax">
                  <span>Acreage</span> 
                  <input id="acreage_from" key="acreage_from" onChange={onChangeInputFilter} value={getFilterValue('acreage_from')} placeholder="Min" inputMode='decimal' onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()} /> 
                  <span className="filter_more_to_label">to</span> 
                  <input id="acreage_to" key="acreage_to" onChange={onChangeInputFilter} value={getFilterValue('acreage_to')} placeholder="Max" inputMode='decimal' onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()} />
                </div>
                {filter.checkboxes.map((idx) => {
                  const option = filter.options.find(x => x.value===idx)
                  return (
                    <div className="filter_option" key={option.title}>
                      <input 
                        type="checkbox" 
                        id={option.value} 
                        name={filter.name}
                        checked={filtersActive.filters.some(
                          (f) => f.name === filter.name && f.value === option.value
                        )} 
                        onChange={onChangeFilter}
                        value={option.value}
                      /> 
                      <label htmlFor={option.value}>{option.title}</label>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  else    // MOBILE MENU
    return (
      <div key={filter.title} className="filters_mobile_body_filter">
        <div
          onClick={() => onClickFilter(id)}
          className="filters_mobile_body_filter_header"
        >
          <h3>{filter.title}</h3>{" "}
          {openFilter === id ? <AiFillCaretUp /> : <AiFillCaretDown />}
        </div>
        <div
          className={
            openFilter === id
              ? "filters_mobile_body_options_active"
              : "filters_mobile_body_options"
          }
        >
          <div className="filters_mobile_list">
          {filter.type === "checkbox" && (
          filter.options.map((option) => {
            return (
              <div className="filters_mobile_body_option" key={option.value}>
                <input
                  onChange={onChangeFilter}
                  name={filter.name}
                  id={option.value}
                  value={option.value}
                  type="checkbox"
                  checked={filtersActive.filters.some(
                    (f) => f.name === filter.name && f.value === option.value
                  )}
                />
                <label htmlFor={option.value}>{option.title}</label>
              </div>
            );
          })
          )
          }
          {filter.type === "price" && (
            <div className="filters_mobile_price">
                from $ <input id="price_from" onChange={onChangeInputFilter} value={getFilterValue('price_from')} inputMode='decimal' onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()} />&nbsp;to&nbsp;
                $ <input id="price_to" onChange={onChangeInputFilter} value={getFilterValue('price_to')} inputMode='decimal' onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()} />            
            </div>
          )}
          {filter.type === "bb" && (
              <div>
              <span>Number of Bedrooms</span>
              <div>
                {filter.options.bedrooms_from.map((option) => {
                  const [value,caption] = [option, option === 0?'Any':option+'+'];
                  return (
                    <button key={value} className={bbBtnClassName('bedrooms_from', value)} onClick={()=>{dispatch(setFilter({name:'bedrooms_from', value}))}}>{caption}</button>
                  )
                })}
              </div>
              <span>Number of Bathrooms</span>
              <div>
                {filter.options.baths_from.map((option) => {
                  const [value,caption] = [option, option === 0?'Any':option+'+'];
                  return (
                    <button key={value} className={bbBtnClassName('baths_from', value)} onClick={()=>{dispatch(setFilter({name:'baths_from', value}))}}>{caption}</button>
                  )
                })}
              </div>
            </div>
          )}
          {filter.type === "more" && (
            <div>
              <div className="filter_more_minmax">
                <span>Square Feet</span> 
                <input id="sqft_from" key="sqft_from" onChange={onChangeInputFilter} value={getFilterValue('sqft_from')} placeholder="Min" inputMode='decimal' onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} /> 
                <span className="filter_more_to_label">to</span> 
                <input id="sqft_to" key="sqft_to" onChange={onChangeInputFilter} value={getFilterValue('sqft_to')} placeholder="Max" inputMode='decimal'  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} />
              </div>
              <div className="filter_more_minmax">
                <span>Acreage</span> 
                <input id="acreage_from" key="acreage_from" onChange={onChangeInputFilter} value={getFilterValue('acreage_from')} placeholder="Min" inputMode='decimal' onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()} /> 
                <span className="filter_more_to_label">to</span> 
                <input id="acreage_to" key="acreage_to" onChange={onChangeInputFilter} value={getFilterValue('acreage_to')} placeholder="Max" inputMode='decimal' onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()} />
              </div>
              {filter.checkboxes.map((idx) => {
                  const option = filter.options.find(x => x.value===idx)
                  return (
                    <div className="filter_more_checkboxes" key={option.title}>
                      <input 
                        type="checkbox" 
                        id={option.value} 
                        name={filter.name}
                        checked={filtersActive.filters.some(
                          (f) => f.name === filter.name && f.value === option.value
                        )} 
                        onChange={onChangeFilter}
                        value={option.value}
                      /> 
                      <label htmlFor={option.value}>{option.title}</label>
                    </div>
                  )
                })}
            </div>
          )}
          </div>
        </div>
      </div>
    );
  
}

export default Filter;
