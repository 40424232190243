import { Route, Routes,  } from "react-router-dom";
import Home from "./pages/Home";
//import Map from "./pages/Map";

import "./scss/app.scss";
import ListingDetails from "./pages/ListingDetails";

function App() {
  const baseURL = window.location.href.indexOf('localhost:3000') == -1 ? '/listings3' : '';
  return (
    <Routes>
      <Route path={`${baseURL}/view/:mls_id`} element={<ListingDetails baseURL={baseURL} />} />
      <Route path={`${baseURL}/`} element={<Home baseURL={baseURL} />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
}

const NotFound = () => {
  return (
    <div className="not-found-container">
      <h1 className="not-found-title">404</h1>
      <p className="not-found-text">Page Not Found</p>
      <a href="/" className="not-found-home-link">Home</a>
    </div>
  );
};

export default App;
