import React from "react";
import Header from "../components/Header";

import ListView from "../components/ListView";
import MapView from "../components/MapView";
import Footer from "../components/Footer";
import ActiveFilters from "../components/ActiveFilters"
import HeadTags from "../components/HeadTags";

function Home({baseURL}) {

  console.log('Home render')
  return (
    <>
      <HeadTags baseURL={baseURL}
            //title = {data.tags.title}
            //metaDescription = {data.tags.description}
      ></HeadTags>
      
      <div style={{'position':'absolute', 'height':'100vh', 'width':'100%'}}>
      <Header baseURL={baseURL} />
      <main>
        <div className="container">
          <ActiveFilters baseURL={baseURL} />
          <ListView baseURL={baseURL} />
          <MapView baseURL={baseURL} />
        </div>

      </main>
      
      <Footer baseURL={baseURL} />
      </div>
    </>
  );
}

export default Home;
