import Navbar from "./Navbar";
import SubNavbar from "./SubNavbar";

function Header({ subnavbar = true, baseURL, mls_id=null }) {
  return (
    <header>
      <Navbar baseURL={baseURL} mls_id={mls_id} />
      {subnavbar ? <SubNavbar /> : ""}
    </header>
  );
}

export default Header;
