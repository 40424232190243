import React from "react";
import { useParams } from 'react-router-dom';
import Header from "../components/Header";
import HeadTags from "../components/HeadTags";
import Footer from "../components/Footer";
import ActiveFilters from "../components/ActiveFilters"
import Details from "../components/Listing/Details";


function ListingDetails( {baseURL} ) {
  const { mls_id } = useParams();
  //console.log('ListingDetails', baseURL, mls_id)
  return (
    <>
      <HeadTags mls_id={mls_id} baseURL={baseURL} />
      <Header subnavbar={false} baseURL={baseURL} mls_id={mls_id} />
      <Details mls_id={mls_id} />
      <Footer baseURL={baseURL} />
    </>
  );
}

export default ListingDetails;
