import { configureStore } from "@reduxjs/toolkit";
import data from "./slices/dataSlice";
import filter from "./slices/filterSlice";
import favourite from "./slices/favouriteSlice";

export const store = configureStore({
  reducer: {
    data,
    filter,
    favourite,
  },
});
