import React from "react";
import ReactPaginate from "react-paginate";

function Pagination({ pageCount, onChangePage, currentPage }) {
  const range = (window.innerWidth <= 640 ? 2 : 3);
  return (
      <div className="pagination">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={(event) => {
            onChangePage(event.selected + 1);
          }}
          pageRangeDisplayed={range}
          marginPagesDisplayed={range}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          forcePage={currentPage - 1}
        />
      </div>
  );
}

/*
      <div className="pagination pagination-sm">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={(event) => {
            onChangePage(event.selected + 1);
          }}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          forcePage={currentPage - 1}
        />
      </div>
*/

export default Pagination;
