import React, {useEffect, useState, useRef} from "react";
import { FaCamera, FaMapMarkedAlt, FaStreetView } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { Md360, MdOutlineViewInAr } from "react-icons/md";
import { renderToStaticMarkup, renderToString } from "react-dom/server";


//import ReactHtmlParser from 'react-html-parser'; 


import { Wrapper, Status } from "@googlemaps/react-wrapper";


import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Thumbs, FreeMode, Zoom } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import './swiper.scss';

function ModalPopup( {closeModal, showModalType, listing, config} ){
    const [showType, setShowType] = useState(showModalType);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const close = () =>{
        closeModal(0);
    }
    const images = listing.imgs;
    const slidesPerView = images.length>4 ? 4 : images.length-1;
    if( thumbsSwiper && thumbsSwiper.destroyed)
        setThumbsSwiper(null)

    return (
        <div className="listing-info_modal" onClick={close}>
            <div className="listing-info_modal_content" onClick={e => e.stopPropagation()}>
                <div style={{'height':'65px'}}>
                <span className="listing-info_modal_close" onClick={close}>&times;</span>
                    <div className="menu">
                        <div className={showType==='Gallery'?"active":null} onClick={ () => setShowType('Gallery')}>
                            <FaCamera size="1rem" /> <span>Photos</span>
                        </div>
                        <div className={showType==='Map'?"active":null} onClick={ () => setShowType('Map')}>
                            <FaMapMarkedAlt size="1rem" /> <span>Map</span>
                        </div>
                        <div className={showType==='StreetView'?"active":null} onClick={ () => setShowType('StreetView')}>
                            <FaStreetView size="1rem" /> <span>Street View</span>
                        </div>
                        {listing.vtours[0] && (
                            <div className={showType==='VirtualTour'?"active":null} onClick={ () => setShowType('VirtualTour')}>
                                <Md360 size="1rem" /> <span>Virtual Tour</span>
                            </div>
                        )}
                    </div>
                </div>
                <div id="modal_content">
                    { showType==='Gallery' && (
                        <div>
                            <Swiper className="modalGallery" pagination={true} modules={[Navigation,Pagination,Thumbs,FreeMode,Zoom]} 
                                thumbs={{ swiper: thumbsSwiper }}
                                pagination={{ clickable: true, dynamicBullets: true }} 
                                slidesPerView={1} 
                                zoom={true}
                                navigation={true}
                                style={images.length<=2?{'height':'75vh'}:{}}
                            >
                              {images.map((img, index) => {
                                return (
                                <SwiperSlide key={index}>
                                    <img src={`${img}`} alt="" />
                                </SwiperSlide>
                                )
                              })}
                            </Swiper>
                            {images.length>2 && (
                            <Swiper
                                onSwiper={setThumbsSwiper}
                                loop={true}
                                spaceBetween={2}
                                slidesPerView={slidesPerView}
                                freeMode={true}
                                watchSlidesProgress={true}
                                modules={[FreeMode, Navigation, Thumbs]}
                                className="modalGalleryThumbs"
                            >
                              {images.map((img, index) => {
                                return (
                                <SwiperSlide key={index}>
                                    <img src={`${img}`} alt="" />
                                </SwiperSlide>
                                )
                              })}
                            </Swiper>
                            )}
                        </div>

                    )}
                    { showType==='Map' && (
                        <MapView config={config} listing={listing} />

                    )}
                    { showType==='StreetView' && (
                        <StreetView config={config} listing={listing} />
                    )}
                    { showType==='VirtualTour' && (
                        <div className="vtour">
                            <h3>Virtual Tour{listing.vtours.length > 1 && 's'}:</h3><br/>
                            {listing.vtours.map( (url, i) => {
                                return (<h4><a href={url} target="_blank"><MdOutlineViewInAr size="1.5rem" /> <span>{url}</span></a></h4>)
                            } ) }
                            
                        </div>
                    )}

                </div>
            </div>
        </div>
    )
}
export default ModalPopup;

function MapView( {config, listing} ) {
    const apiKey = config.gmapToken;
    if (!apiKey) {
      return <div>Cannot display the map: google maps api key missing</div>;
    }
    return (
      <Wrapper apiKey={apiKey} render={render}>
        <MlsMap zoom={19} listing={listing} />
      </Wrapper>
    );
}
function MlsMap({
    zoom,
    listing,
  }) 
{
    const ref = useRef();
    const google = window.google
    console.log('center:', { lat: listing.lat, lng: listing.lng })
    useEffect(() => {
      if (ref.current) {
        async function initMap() {
          const { Map, InfoWindow } = await google.maps.importLibrary("maps");
          const markerLib = await google.maps.importLibrary("marker");
          const googleMap = new Map(ref.current, {
            center: { lat: listing.lat, lng: listing.lng },
            zoom,
            mapId: "AQUA_MAP",
            mapTypeId: 'roadmap',
          });
  
          const content = document.createElement("div");    //add Marker
          content.classList.add("propertyLabel");
          content.innerHTML = "<div>"+renderToString(<AiOutlineHome size={30} />)+"</div>";
          const marker = new markerLib.AdvancedMarkerElement({
            position: { lat: listing.lat, lng: listing.lng },
            content: content,
            map: googleMap,
          });
        }
        initMap();
      }
    }, [ref]);
  
    return <div ref={ref} id="map" className="Map" style={{'height':'100%'}} />;
}
function StreetView( {config, listing} ) {
    const apiKey = config.gmapToken;
    if (!apiKey) {
      return <div>Cannot display the map: google maps api key missing</div>;
    }
    return (
      <Wrapper apiKey={apiKey} render={render}>
        <MlsStreetView zoom={19} listing={listing} />
      </Wrapper>
    );
}
function MlsStreetView({
    zoom,
    listing,
  }) 
{
    const ref = useRef();
    const google = window.google
    useEffect(() => {
      if (ref.current) {
        async function initMap() {
          const { Map } = await google.maps.importLibrary("maps");
          const googleMap2 = new Map(ref.current, {
            center: { lat: listing.lat, lng: listing.lng },
            zoom,
            mapId: "AQUA_MAP2",
          });
          const panorama = new google.maps.StreetViewPanorama(ref.current, {
            position: { lat: listing.lat, lng: listing.lng },
            map: googleMap2,
          });
          console.log('panorama',panorama)
        }
        initMap();
      }
    }, [ref]);
    return <div ref={ref} id="map2" className="Map" style={{'height':'100%'}} />;
}
const render = (status: Status): ReactElement => {
    if (status === Status.LOADING) return <h3>{status} ..</h3>;
    if (status === Status.FAILURE) return <h3>{status} ...</h3>;
    return null;
};
