import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import qs from "qs";
import { AiOutlineClose } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { selectData } from "../redux/slices/dataSlice";
import { selectFilter, setSearchValue, setFilter, removeFilter, buildFilterParams } from "../redux/slices/filterSlice";

function Navbar({baseURL, mls_id}) {
  const filter = useSelector(selectFilter);
  const searchValue = filter.searchValue;
  const [query, setQuery] = useState("");
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { config } = useSelector(selectData);
  const [results, setResults] = useState([])
  const navigate = useNavigate();

  const onClickSearchButton = () => {
    if(query === ""){
      dispatch(removeFilter({name:'q', value:'*'}));
    }
    else{
      dispatch(setFilter({name:'q', value:query}));
      setResults([]);
      dispatch(setSearchValue(''));
      if(mls_id){
        let filterParams = buildFilterParams(filter);
        filterParams['q'] = query;
        console.log('filterParams', filterParams)
        const queryString = qs.stringify({...filterParams}, { skipNulls: true });
        navigate(baseURL + '/?' + queryString);
      }
    }
  };

  const onChangeInput = (event) => {
    dispatch(setSearchValue(event.target.value));
    if(event.target.value === ""){
      dispatch(removeFilter({name:'q', value:'*'}));
    }
  };
  useEffect(() => {
    const fetchSearchData = async (q) => {
      if(q.length>=2){
        try {
            let {data} = await axios.get(`${config.apiURL}search?q=${q}`);
            data = JSON.parse(data)
            setResults(data.results)
        } catch (error) {
            console.error(error)
        }
      }
    }
    fetchSearchData(query);
  }, [query])

  useEffect(() => {
    setQuery(searchValue);
  },[searchValue])

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const addFilter = (f, e) => {
    dispatch(setSearchValue(''));
    if(f[0] !== 'mls_id'){
      setResults([]);
      e.preventDefault();
      dispatch(setFilter({name: f[0], value: f[1]}));
      if(mls_id){
        let filterParams = buildFilterParams(filter);
        filterParams[f[0]] = f[1];
        console.log('filterParams', filterParams)
        const queryString = qs.stringify({...filterParams}, { skipNulls: true });
        navigate(baseURL + '/?' + queryString);
      }
    }
  };

  //console.log('results',results)

  return (
    <div className="navbar">
      <div className="container">
        <div className="logo">{<Link to={config.companyURL}>{config.companyName}</Link>}</div>
        <div className="menu_toggle" onClick={handleToggle}>
          {isOpen ? <AiOutlineClose /> : <GiHamburgerMenu />}
        </div>
        <div className={"menu_collapse"}>
          <div className={isOpen ? "menu active" : "menu"}>
            <div className="search">
              <input
                value={query}
                onChange={(event) => {
                  onChangeInput(event);
                }}
                onKeyDown = { e => e.key === 'Enter' ? onClickSearchButton() : null }
                type="text"
                placeholder="City, County, MLS Id, Zip"
              />
              <button onClick={onClickSearchButton}>Search</button>
              { results.length>0 && ( 
              <div className="results-wraper">
                  {results.map(item => (
                      <div key={item.id}>
                          <Link to={baseURL+item.url} onClick={ (e) => addFilter(item.addFilter,e) }>{item.name} <span> {item.type}</span></Link>
                      </div>
                  ))}
              </div>
              ) }
            </div>
            <Link to="/">
              <button className="home">Home</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
