import React from "react";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { selectFilter, setSort } from "../redux/slices/filterSlice";

const Sort = ({ id, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { sort } = useSelector(selectFilter);

  const onClickOption = (value) => {
    dispatch(setSort(value));
    if (typeof setIsOpen !== "undefined") {
      setIsOpen(-1);
    }
  };

  const sortList = [
    { title: "Price (Low To High)", value: "priceLtH" },
    { title: "Price (High To Low)", value: "priceHtL" },
    { title: "Newest To Oldest", value: "newest" },
    { title: "Oldest To Newest", value: "oldest" },
    { title: "Bedrooms (Low To High)", value: "bedroomsLtH" },
    { title: "Bedrooms (High To Low)", value: "bedroomsHtL" },
    { title: "Bathrooms (Low To High)", value: "bathroomsLtH" },
    { title: "Bathrooms (High To Low)", value: "bathroomsHtL" },
  ];

  return (
    <>
      <div className="sort">
        <div
          onClick={() => setIsOpen(id)}
          className={isOpen ? "sort_header open" : "sort_header"}
        >
          Sort by
          {!isOpen ? <AiFillCaretDown /> : <AiFillCaretUp />}
        </div>
        {isOpen ? (
          <div className={"sort_body"}>
            {sortList.map((sortOption, index) => {
              return (
                <div
                  key={index}
                  onClick={() => onClickOption(sortOption.value)}
                  className={
                    sort === sortOption.value
                      ? "sort_option sort_option_active"
                      : "sort_option"
                  }
                >
                  {sortOption.title}
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="sort_mobile">
        <h3>Sort By</h3>
        <select
          defaultValue={sort}
          onChange={(e) => onClickOption(e.target.value)}
          name=""
          id=""
        >
          {sortList.map((option, index) => {
            return (
              <option key={index} value={option.value}>
                {option.title}
              </option>
            );
          })}
        </select>
      </div>
    </>
  );
};

export default Sort;
