import { selectData } from "../redux/slices/dataSlice";
import { selectFilter, setCurrentPage } from "../redux/slices/filterSlice";
import Skeleton from "../components/Listing/Skeleton";
import { useSelector, useDispatch } from "react-redux";
import Listing from "../components/Listing";
import Pagination from "../components/Pagination";


function ListView( {baseURL} ) {
    const dispatch = useDispatch();
    const filter = useSelector(selectFilter);
    const data = useSelector(selectData);
    const listingsPerPage = 60;
    const skeletons = [...new Array(20)].map((_, index) => (
        <Skeleton key={index} />
    ));
    const onPageChange = (page) => {
        dispatch(setCurrentPage(page));
        window.scrollTo(0, 0);
    };
    //console.log('ListView', filter, data)
    if(filter.view !== "List")
        return ("")
    return (
        <>
          <div className="listings">
            {data.status === "success"
              ? data.listings
                  .slice(
                    listingsPerPage * (filter.currentPage - 1),
                    listingsPerPage * filter.currentPage
                  )
                  .map((listing) => {
                    //const isFavourite = favourite.favListings.filter( (obj) => obj.mls_id === listing.mls_id ).length > 0;
                    //console.log(listing)
                    return (
                      <Listing
                        key={listing.mls_id}
                        {...listing}
                        baseURL={baseURL}
                        //isFavourite={isFavourite}
                      />
                    );
                  })
              : skeletons}
          </div>
          {data.status === "success" && data.pageCount>0 && filter.view=='List' && (
                <Pagination
                  currentPage={filter.currentPage}
                  onChangePage={onPageChange}
                  pageCount={data.pageCount}
                />
          )}
        </>
    )
}
export default ListView;
